/**
 * @generated SignedSource<<8145de8d7c6340be896a047462b60c3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultCountryCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationDashboard_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationDetailsForm_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScoreCardPreview_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerOrganisationsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tournamentPlayersCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tournamentsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlayerOrganisationConnection",
            "kind": "LinkedField",
            "name": "playerOrganisations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlayerOrganisation",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tournament",
            "kind": "LinkedField",
            "name": "tournaments",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14caaad6bada866a720e64a80a88aa68",
    "id": null,
    "metadata": {},
    "name": "OrganisationDetailsQuery",
    "operationKind": "query",
    "text": "query OrganisationDetailsQuery(\n  $id: ID!\n) {\n  organisation(id: $id) {\n    defaultCountryCode\n    name\n    ...OrganisationDashboard_organisation\n    ...OrganisationDetailsForm_organisation\n    ...ScoreCardPreview_organisation\n    id\n  }\n}\n\nfragment OrganisationDashboard_organisation on Organisation {\n  id\n  name\n  playerOrganisationsCount\n  tournamentPlayersCount\n  tournamentsCount\n  ...OrganisationMembershipChart_organisation\n  ...OrganisationTournamentChart_organisation\n}\n\nfragment OrganisationDetailsForm_organisation on Organisation {\n  color\n  defaultCountryCode\n  id\n  name\n  ...ScoreCardPreview_organisation\n}\n\nfragment OrganisationMembershipChart_organisation on Organisation {\n  playerOrganisations {\n    nodes {\n      startDate\n      endDate\n      id\n    }\n  }\n}\n\nfragment OrganisationTournamentChart_organisation on Organisation {\n  tournaments {\n    startDate\n    id\n  }\n}\n\nfragment ScoreCardPreview_organisation on Organisation {\n  color\n}\n"
  }
};
})();

node.hash = "aefd5640a1b6102e53c021dc3fa57a52";

module.exports = node;
